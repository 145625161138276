import { WeylandFavoriteItem } from '../../interfaces/favorite-item.model';
import { ArticleFactory, FavoriteItemQuery, FavoriteItemStore } from '@lobos/library';
import { Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { WeylandSteelArticle } from '../../interfaces/steel-article.model';

@Injectable({ providedIn: 'root' })
export class WeylandFavoriteItemQuery<T extends WeylandFavoriteItem> extends FavoriteItemQuery<T> {
  constructor(protected override store: FavoriteItemStore<T>) {
    super(store);
  }

  override selectFavoriteItems(favoriteListId: ID): Observable<T[]> {
    return this.selectAll({
      filterBy: ({ gListID }) => gListID === favoriteListId,
    }).pipe(
      map((favoriteItems: T[]) =>
        favoriteItems.map((favoriteItem: T) => {
          return { ...favoriteItem, oArticle: ArticleFactory.create(favoriteItem.oArticle) as WeylandSteelArticle };
        }),
      ),
    );
  }
}
