<ng-container *transloco="let t; scope: 'core'">
  <div class="card-fav-item">
    <div class="card-inner">
      <div class="card-image">
        <app-image [image]="favoriteItem.oArticle | articleMainImage"></app-image>
      </div>
      <div class="card-content">
        <div class="card-content-head">
          <div class="card-content-head-inner">
            <p class="article-name">
              <a [routerLink]="['article' | localizeUrlPath, favoriteItem.oArticle.sArticleID]">{{ favoriteItem.oArticle.sName }}</a>
            </p>
            <p class="article-number">
              {{ t("core.header-nav.favorite-menu.article-number") }}: <strong>{{ favoriteItem.oArticle.sArticleID }}</strong>
            </p>
          </div>
          <div class="card-content-head-input">
            <app-quantity-input [quantity]="favoriteItem.decQuantity" (onQuantityChange)="onQuantityChange(favoriteItem, $event)">
            </app-quantity-input>
            <a class="deleteDesktop" (click)="deleteFavoriteItem(favoriteItem)">
              <i class="icon-trash"></i>
            </a>
          </div>
        </div>
        <div class="card-content-body">
          <div class="card-content-body-price">
            <strong>
              <app-article-price [article]="favoriteItem.oArticle" [bGrossPrice]="false"></app-article-price>
            </strong>
          </div>
          <div class="card-content-body-cta">
            <a (click)="onAddArticleToCartClick(favoriteItem)">
              <i class="icon-cart"></i>
            </a>
            <a class="deleteMobile" (click)="deleteFavoriteItem(favoriteItem)">
              <i class="icon-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
