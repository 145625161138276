import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';
import { AuthModule, DebounceClickModule, LocalizeUrlPathModule, UrlEncodingModule } from '@lobos/library';
import { popperVariation, TippyModule, tooltipVariation } from '@ngneat/helipopper';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MarkdownModule } from 'ngx-markdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ArticleAvailabilityDialogComponent } from './article-availability/article-availability-dialog.component';
import { ArticleAvailabilityComponent } from './article-availability/article-availability.component';
import { IsFavoriteComponent } from './article-is-favorite/is-favorite.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { ArticlePriceComponent } from './article-price/article-price.component';
import { QuantityInputComponent } from './article-quantity-input/quantity-input.component';
import { ArticleUnitComponent } from './article-unit/article-unit.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CartTypeMismatchModalComponent } from './cart-type-mismatch-modal/cart-type-mismatch-modal.component';
import { ConfirmTooltipComponent } from './confirm-tooltip/confirm-tooltip.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { FooterMainComponent } from './footer/footer-main/footer-main.component';
import { FooterNotesComponent } from './footer/footer-notes/footer-notes.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderInfoComponent } from './header/header-info/header-info.component';
import { HeaderMainComponent } from './header/header-main/header-main.component';
import { CartMenuComponent } from './header/header-nav/cart-menu/cart-menu.component';
import { FavoriteItemComponent } from './header/header-nav/favorite-menu/favorite-item/favorite-item.component';
import { FavoriteMenuComponent } from './header/header-nav/favorite-menu/favorite-menu.component';
import { HeaderNavComponent } from './header/header-nav/header-nav.component';
import { ProfileMenuComponent } from './header/header-nav/profile-menu/profile-menu.component';
import { CatalogMenuComponent } from './header/header-navigation/catalog-menu/catalog-menu.component';
import { CmsMenuComponent } from './header/header-navigation/cms-menu/cms-menu.component';
import { HeaderNavigationComponent } from './header/header-navigation/header-navigation.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { HeaderComponent } from './header/header.component';
import { MenuItemComponent } from './header/menu-item/menu-item.component';
import { MenuComponent } from './header/menu/menu.component';
import { ImageComponent } from './image/image.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { MobileFooterNavComponent } from './mobile-footer-nav/mobile-footer-nav.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ScanButtonComponent } from './scanner/scan-button/scan-button.component';
import { ScanResultComponent } from './scanner/scan-result/scan-result.component';
import { ScannerComponent } from './scanner/scanner/scanner.component';
import { TitleAndTextComponent } from './title-and-text/title-and-text.component';
import { ScannerMobileComponent } from './mobile-footer-nav/scanner-mobile/scanner-mobile.component';
import { FavoriteComponent } from './mobile-footer-nav/favorite/favorite.component';
import { CartComponent } from './mobile-footer-nav/cart/cart.component';
import { ProfileComponent } from './mobile-footer-nav/profile/profile.component';
import { CheckoutComponentsModule } from '../features/checkout/checkout-components/checkout-components.module';

const components: any[] = [
  MainLayoutComponent,
  BreadcrumbComponent,
  FooterComponent,
  FooterMainComponent,
  FooterNotesComponent,
  HeaderComponent,
  HeaderInfoComponent,
  HeaderMainComponent,
  HeaderNavComponent,
  CartMenuComponent,
  FavoriteMenuComponent,
  ProfileMenuComponent,
  HeaderNavigationComponent,
  CatalogMenuComponent,
  CmsMenuComponent,
  HeaderSearchComponent,
  MenuComponent,
  MenuItemComponent,
  LoginDialogComponent,
  ScannerComponent,
  ScanButtonComponent,
  ScanResultComponent,
  ArticlePriceComponent,
  ArticleUnitComponent,
  IsFavoriteComponent,
  PaginationComponent,
  QuantityInputComponent,
  ArticleAvailabilityComponent,
  ArticleAvailabilityDialogComponent,
  ArticleItemComponent,
  ImageComponent,
  ProductItemComponent,
  ArticleAvailabilityDialogComponent,
  ConfirmTooltipComponent,
  ForgotPasswordComponent,
  FavoriteItemComponent,
  TitleAndTextComponent,
  CartTypeMismatchModalComponent,
  CookieConsentComponent,
  MobileFooterNavComponent,
  ScannerMobileComponent,
  FavoriteComponent,
  CartComponent,
  ProfileComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    DirectivesModule,
    RouterModule,
    TranslocoModule,
    DebounceClickModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    LocalizeUrlPathModule,
    PipesModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatSelectModule,
    FormsModule,
    MarkdownModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    UrlEncodingModule,
    CloudinaryModule,
    TranslocoLocaleModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatInputModule,
    AuthModule,
    CheckoutComponentsModule,
  ],
  exports: components,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: '' }],
})
export class ComponentsModule {}
