<ng-container *ngIf="shtStockLevel$ | async as shtStockLevel">
  <div
    [class.availabilitySpinner]="loading"
    *ngIf="shtStockLevel > 0"
    class="delivery-status default-font-sizes default-font-sizes--ultra-small {{ availableCSS }}"
    title="{{ availableHint }}"
    (click)="openAvailability($event, shtStockLevel)"
    [ngClass]="(user$ | async)?.shtRoleID === 1 || (user$ | async)?.shtRoleID === 2 ? 'margin-left-10' : ''"
  >
    <span class="delivery-status-dot"></span>
    <ng-container *ngIf="stockAvailability$ | async as oStockAvailability">
      <ng-container *ngIf="(user$ | async)?.shtRoleID !== 1 && (user$ | async)?.shtRoleID !== 2" style="margin-left: 5px">
        <ng-container *ngIf="shtStockLevel > 2">
          <span>{{ oStockAvailability.decAvailable }}&nbsp;{{ oStockAvailability.sQuantityUnit }}</span>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
