import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AuthService,
  Parameter,
  ParameterQuery,
  StockAvailability,
  StockAvailabilityQuery,
  StockAvailabilityService,
  User,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { ArticleAvailabilityDialogComponent } from './article-availability-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-article-availability',
  templateUrl: './article-availability.component.html',
  styleUrls: ['./article-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ArticleAvailabilityComponent {
  public stockAvailability$!: Observable<StockAvailability>;
  public availableCSS = '';
  public availableHint = '';
  public shtStockLevel$: Observable<number>;
  public loading = true;
  protected _shtStockLevel: number | undefined;
  protected isLoggedIn$: Observable<boolean> | undefined;
  user$: Observable<User | undefined> = this.authService.authUser$;

  constructor(
    protected dialog: MatDialog,
    protected stockAvailabilityService: StockAvailabilityService,
    protected stockAvailabilityQuery: StockAvailabilityQuery,
    protected authService: AuthService,
    protected paramQuery: ParameterQuery,
    protected translocoService: TranslocoService,
    private ref: ChangeDetectorRef,
  ) {
    this.shtStockLevel$ = combineLatest([this.authService.authUser$, this.authService.isLoggedIn$, this.paramQuery.params$]).pipe(
      distinctUntilChanged(([a, b, _]: [User | undefined, boolean, Parameter | undefined]) => isEqual(a, b)),
      switchMap(([currentUser, isLoggindIn, oParameter]: [User | undefined, boolean, Parameter | undefined]) => {
        this._shtStockLevel =
          isLoggindIn && currentUser?.oP48APIAuthorizationGroup
            ? currentUser.oP48APIAuthorizationGroup.shtStockLevel
            : oParameter!.shtStockLevel;
        this.getAvailability();
        return isLoggindIn && currentUser?.oP48APIAuthorizationGroup
          ? of(currentUser.oP48APIAuthorizationGroup.shtStockLevel)
          : of(oParameter!.shtStockLevel);
      }),
    );
  }

  protected _sArticleID: string | number | undefined;

  @Input()
  set sArticleID(sArticleIDP: string | number) {
    this._sArticleID = sArticleIDP;

    this.stockAvailability$ = this.stockAvailabilityQuery.selectEntity(sArticleIDP).pipe(
      filter((oStockAvailability: StockAvailability | undefined): oStockAvailability is StockAvailability => !!oStockAvailability),
      tap((oStockAvailability: StockAvailability) => {
        this.handleStockAvailability(oStockAvailability);
        this.loading = false;
        this.ref.detectChanges();
      }),
    );
    this.getAvailability();
  }

  private _decQty = 1; //ToDo: initial Value MengeV

  @Input()
  set decQty(decQtyP: number) {
    this._decQty = decQtyP;

    this.getAvailability();
  }

  public openAvailability(e: Event, shtStockLevel: number) {
    if (shtStockLevel > 2) {
      this.dialog.open(ArticleAvailabilityDialogComponent, {
        height: 'auto',
        width: '30%',
        data: {
          stockAvailability$: this.stockAvailability$,
          shtStockLevel: shtStockLevel,
          decQtyRequested: this._decQty,
        },
      });
    }
    e.preventDefault();
  }

  protected getAvailability() {
    if (this._shtStockLevel! > 0) {
      if (!this.stockAvailabilityQuery.hasEntity(this._sArticleID)) {
        this.stockAvailabilityService.get(this._sArticleID!).pipe(take(1), untilDestroyed(this)).subscribe();
      }
    }
  }

  protected handleStockAvailability(oStockAvailability: StockAvailability) {
    if (this._shtStockLevel && this._shtStockLevel <= 2) {
      if (oStockAvailability.shtAvailable <= 0) {
        this.availableCSS = 'unavailable';
        this.availableHint = this.translocoService.translate('shared.article-availability.status.unavailable');
      } else {
        this.availableCSS = 'available';
        this.availableHint = this.translocoService.translate('shared.article-availability.status.available');
      }
    } else {
      if (oStockAvailability.decAvailable <= 0) {
        this.availableCSS = 'unavailable';
        this.availableHint = this.translocoService.translate('shared.article-availability.status.unavailable');
      } else if (oStockAvailability.decAvailable > 0 && oStockAvailability.decAvailable >= this._decQty) {
        this.availableCSS = 'available';
        this.availableHint = this.translocoService.translate('shared.article-availability.status.available');
      } else if (oStockAvailability.decAvailable > 0 && oStockAvailability.decAvailable < this._decQty) {
        this.availableCSS = 'temp-unavailable';
        this.availableHint = this.translocoService.translate('shared.article-availability.status.temp-unavailable');
      }
    }
  }
}
